:root{
  --text_colour: #042861;
  --bg_colour: #e0f9fb;
  --lift_pink: #ef2cb1;
  --lift_blue: #0f73d7;
  --lift_red: #f64f59;
}


html,body{
  background-color:var(--bg_colour);
  display: flex;
  flex-direction: column;
  width:100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden  ;
}

@font-face {
  font-family: 'Abel';
  src: url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
  font-display: swap;
}


.main{
    margin-top: 10%; 
    min-height: 100%;
    margin-bottom: -10px; 
}
.main:after{
  content: "";
  display: block;
  
}
.page-title{
  vertical-align: center;
  text-align: center;
  color: var(--text_colour);
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  font-size: 6vw;
  padding-top: 5%
}

.img {
  max-width: 100%
}

.top-half{
  display: flex;
  justify-content: center ;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding-block: 5rem;
  
}

.bottom-half{
  --skew_angle: 5deg;
  --background: 
  linear-gradient(
    45deg,
      var(--lift_blue),
      var(--lift_pink),
      var(--lift_red));
      display: flex;
      position: relative;
      justify-content: center ;
      align-items: center;
      height: 100%;
      padding-block: 5rem;
      isolation: isolate;
}
.bottom-half::after{
  content:'';
  background: var(--background);
  position: absolute;
  z-index:-1 ;
  inset: 0;
  transform: skewY(var(--skew_angle));
}

#homeText{
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  font-size: 2.5vw;
  color: var(--text_colour);
}


.wrapper{
  max-width: 71rem;
  margin-inline: auto;
  padding-inline: 1rem;
}

#mission{
  font-family: 'Abel', sans-serif;
  font-size: 3vw;
  color: white;
  width: 100%;
}

.credit{
  display: flex;
  justify-content: flex-end;
  font-size: 2vw;
  color: white;
}


#home-text-animated{
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  --bg-size: 400%;
  --color-one: var(--lift_pink);
  --color-two: var(--lift_blue);
  font-size: inherit;
  background: linear-gradient(
    90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 8s infinite linear;
}
@media (prefers-reduced-motion: no-preference) {
  #home-text-animated {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

#home-logo{
  max-width: 15%;
  aspect-ratio: 1/1;
}
#header-logo{
  aspect-ratio: 1/1;
}


.social{
  color: white;
}

.mainForm{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

@media only screen and (max-width: 1000px) {
  .main{
    margin-top: 20%; 
    min-height: 100%;
    margin-bottom: -10px; 
  }
  .mainForm{
    height: 200vh;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
  }
  .itemImage{
    flex: 1 1 0%;
    position: relative;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .itemImage img{
    width: 90% !important;
    height: 90% !important;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  .formSection{
    background-color: white;
    display: flex;
    border-radius: 0.5rem;
    width: 80%;
    height: 100%;
    flex-direction: column;
  }
  .leftSideForm{
    flex: 1 1 0%;
    color: rgb(55 65 81);
    padding-top: 80px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-bottom: 0px !important;
  }
  .homeContent{
    display: flex;
    justify-content: center ;
    align-items: center;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
  }

  .inputName{
    border-width: 2px;
    border-color: rgb(107 114 128);
    color: rgb(61,61,61);
    padding: 0.5rem;
    border-radius: 0.375rem;
    width: 75%;
  }
  #item-desktop{
    display: none !important;
  }
  #item-mobile{
    display: block !important;
  }
  
  .page-title{
    vertical-align: center;
    text-align: center;
    color: var(--text_colour);
    font-family: Copperplate, "Copperplate Gothic Light", fantasy;
    font-size: 6vw;
    padding-top: 15%
  }

  #homeText{
    font-family: Copperplate, "Copperplate Gothic Light", fantasy;
    font-size: 5vw;
    color: var(--text_colour);
    width: 75%;
  }
  .divider{
    margin-top: 0 !important; 
    border-top: 3px solid var(--text_colour);
    width: 90%
  }
  #success{
    position: fixed;
    z-index: 10;
    color:var(--text_colour);
    font-size: 1vw;
    border-radius: 15px !important;
    width: 75%;
  }
  .order-submitted{
    font-family: Copperplate, "Copperplate Gothic Light", fantasy;
    font-size: 4vw !important;
  }
  .order-time{
    font-family: Copperplate, "Copperplate Gothic Light", fantasy;
    font-size: 2.5vw !important;
  }
  #close {
    color: var(--text_colour);
    position: absolute;
    top: 0px;
    right: 2%;
    font-size: 4.5vw !important;
    font-weight: bold;
  }
  #close:hover,.close:focus {
    text-decoration: none;
    cursor: pointer;
  }
  .about-desc{
    font-size: 1rem !important;
  }
  .hidden{
    opacity: 0;
    transition: all 1s;
    filter: blur(5px);
    transform:translate(0,50%)
  }
  
  .show{
    opacity: 1;
    filter: blur(0);
    transform: translate(0,0);
  }
}

.formSection{
  background-color: white;
  display: flex;
  border-radius: 0.5rem;
  width: 80%;
  height: 100%;
}
.leftSideForm{
  flex: 1 1 0%;
  color: rgb(55 65 81);
  padding-top: 80px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
}
.formTitle{
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-family: 'Courier New', Courier, monospace;
  color: var(--text_colour);
  padding-bottom: 0.5rem;
}
.formDesc{
  /* font-size: 1.125rem; */
  line-height: 1.75rem; 
  color: var(--text_colour);
  padding-bottom: 0.5rem;
  font-weight: bold;
  
}
.formDiv{
  margin-top: 1.5rem;
}
.field{
  padding-bottom: 1rem;
}
.label{
  display: block;
  padding-bottom: 0.5rem;
}
.error-label{
  display: block;
  padding-bottom: 0.5rem;
  color: rgb(248 113 113);
}
.inputName{
  border-width: 2px;
  border-color: rgb(107 114 128);
  color: rgb(61,61,61);
  padding: 0.5rem;
  border-radius: 0.375rem;
  width: 75%;
}
.inputName:focus{
  border-color: var(--text_colour);
  --tw-ring-color: var(--text_colour);
}
#item-desktop{
  display: block;
}
#item-mobile{
  display:none;
}
.checkbox{
  min-height:20px;
  min-width:20px;
}
.checkbox:hover{
  cursor: pointer;
}

.loading-button,.submitButton{
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  color: var(--text_colour);
  font-weight: bold;
  padding-top: 0.75rem; /* 12px */
  padding-bottom: 0.75rem; /* 12px */ 
  margin-top: 1.5rem; 
  border-radius: 0.5rem; 
  width: 100%;
  border-style: solid;
  border-color: var(--text_colour);
  background-color: var(--bg_colour);
}
.submitButton{
  opacity: 0.6;
  transition: 0.3s;
}
.submitButton:hover{
  opacity: 1;
} 
.buttonLoad{
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  color: var(--text_colour);
  font-weight: bold;
  padding-top: 0.75rem; /* 12px */
  padding-bottom: 0.75rem; /* 12px */ 
  margin-top: 1.5rem; 
  border-radius: 0.5rem; 
  width: 100%;
  border-style: solid;
  border-color: var(--text_colour);
  background-color: var(--bg_colour);
  opacity: 0.6;
  transition: 0.3s;
}
.fa {
  margin-left: -12px;
  margin-right: 8px;
}
.itemImage{
  flex: 1 1 0%;
  position: relative;
  border-radius: 0.5rem;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemImage img{
  width: 100%;
  height: 90%;
  object-fit: cover;
  border-radius: 0.5rem;
}
.terms{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
#success{
  position: fixed;
  z-index: 10;
  color:var(--text_colour);
  font-size: 1vw;
  border-radius: 50px;
}
.order-submitted{
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  font-size: 2vw;
}
.order-time{
  font-family: Copperplate, "Copperplate Gothic Light", fantasy;
  font-size: 1.5vw;

} 
#close {
  color: var(--text_colour);
    position: absolute;
    top: 0px;
    right: 4%;
    font-size: 2vw;
    font-weight: bold;
  }
  #close:hover,.close:focus {
  text-decoration: none;
  cursor: pointer;
}
.divider{
  margin-top: 4rem;
  border-top: 3px solid var(--text_colour);
  width: 90%
}

.aboutPage{
  margin: 0;
  color: white;
  background: var(--lift_pink);
}

.about-wrapper{
  margin-top: 50px;
}

.curve-section{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Rubik', sans-serif;
  color:white;
  padding: 100px 20vw;
}

.about-page-title{
  font-size: 6vh;
  display: flex;
  align-items: center;
}


#blue{
  background: var(--lift_blue);
  text-align: right;
  align-items: flex-end;
}
#pink{
  background: var(--lift_pink);
  text-align: left;
}


.spacer {
    aspect-ratio: 960/150;
    width:100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .layer1 {
    background-image: url(../images/layered-waves-haikei2.svg);
  }
  .layer2 {
    background-image: url(../images/layered-waves-haikei.svg);
  }

.tenet-title{
  font-size: 3rem;
  /* text-decoration: underline; */
}

.about-desc{
  font-size: 2rem;
}

@media(prefers-reduced-motion) {
  .hidden{
    transition: none;
  }
}

.hidden{
  opacity: 0;
  transition: all 1s;
  filter: blur(5px);
  transform:translate(0,100%)
}

.show{
  opacity: 1;
  filter: blur(0);
  transform: translate(0,0);
}
